export const B2B_BASE_URL = "/foretag/mybusiness";
export const B2B_LOGIN_URL = `${B2B_BASE_URL}`; // Possible subject to change - but same as base url currently ...
export const B2B_START_PAGE_URL = `${B2B_BASE_URL}/start`;
export const B2B_NO_ACCESS_URL = `${B2B_BASE_URL}/no-access`;
export const B2B_NOT_FOUND_404_URL = `${B2B_BASE_URL}/page-not-found`;
export const B2B_GENERAL_ERROR_URL = `${B2B_BASE_URL}/something-went-wrong`;
export const B2B_NOT_AUTHORIZED_URL = `${B2B_BASE_URL}/not-authorized`;
export const B2B_ORGANISATION_REGISTRATION_BASE_URL = `${B2B_BASE_URL}/skaffa-mybusiness`;
export const B2B_ORGANISATION_REGISTRATION_NOT_LOGGED_IN_URL = `${B2B_ORGANISATION_REGISTRATION_BASE_URL}/inte-inloggad`;
export const B2B_ORGANISATION_REGISTRATION_LOGGED_IN_URL = `${B2B_ORGANISATION_REGISTRATION_BASE_URL}/inloggad`;
export const B2B_TERMS_URL = `${B2B_BASE_URL}/om/villkor-mybusiness`;
export const B2B_ACCEPT_INVITE_URL = `${B2B_BASE_URL}/inbjudan`;
export const B2B_ACCEPT_INVITE_URL_NOT_LOGGED_IN_URL = `${B2B_ACCEPT_INVITE_URL}/inte-inloggad`;
export const B2B_ACCEPT_INVITE_URL_LOGGED_IN_URL = `${B2B_ACCEPT_INVITE_URL}/inloggad`;
export const B2B_MANAGE_USERS_URL = `${B2B_BASE_URL}/hantera/anvandare`;
export const B2B_EDIT_USER_BASE_URL = `${B2B_MANAGE_USERS_URL}/editera`;
export const B2B_CREATE_INVITE_URL = `${B2B_MANAGE_USERS_URL}/bjudin`;

// TODO: depre urls are best urls?
export const OLD_B2B_LOGIN_URL = `${B2B_BASE_URL}/login`;
export const B2B_REGISTER_COMPANY_URL = `${B2B_BASE_URL}/registrera`;
export const B2B_REQUEST_NEW_PASSWORD_URL = `${B2B_BASE_URL}/begar-nytt-losenord`;
export const B2B_FORGOT_USERNAME_URL = `${B2B_BASE_URL}/glomt-anvandarnamn`;
export const INVITE_URL = `${B2B_BASE_URL}/inbjudan`;
// Cots Urls
export const COTS_BASE_URL = "/foretag/mybusiness/telia-admin/hantera_ny";
export const TELIA_MOCKING_JAY_COOKIE = "telia-mocking-jay"; // Used when COTS
export const TELIA_ADMIN_URL = `${B2B_BASE_URL}/telia-admin`;
