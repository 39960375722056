import { MessageProvider } from "@messageformat/react";

import { useMessageLocale } from "./hooks/useMessageLocale";
import React from "react";
import { registerIconsBundle } from "@teliads/icons";

type Props = {
  children: React.ReactNode;
};

export function Scaffolding({ children }: Props) {
  registerIconsBundle();

  const messages = useMessageLocale();
  if (!messages) {
    return <div />;
  }

  return <MessageProvider messages={messages}>{children}</MessageProvider>;
}
