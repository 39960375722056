import { useEffect, useState } from "react";
import { MessageObject } from "@messageformat/react";
import { currentLanguage } from "@telia/b2b-i18n";

import en from "../locales/locale.en.yaml";
import sv from "../locales/locale.sv.yaml";

export function useMessageLocale() {
  const [messages, setMessages] = useState<MessageObject>();
  const [currentLocale] = useState<string>(currentLanguage()); // defaults to sv if no cookie set ...

  useEffect(() => {
    if (currentLocale === "en") {
      setMessages(en);
    } else {
      setMessages(sv);
    }
  }, [currentLocale]);
  return messages;
}
