import React from "react";
import { useMessage } from "@messageformat/react";
import styled from "styled-components";
import { CtaLink, Grid, Heading, Paragraph } from "@purpurds/purpur";
import { purpurBreakpointRelativeMd } from "@purpurds/tokens"; // Does not work when impl. according to purpur Doc - seems broken!

import PageNotFoundSvg from "../../assets/graphics/page-not-found-alert.svg";
import { B2B_BASE_URL } from "@telia/b2b-utils/src/utils/constants";

const PageContainer = styled.div`
  background-color: var(--purpur-color-background-secondary);
  padding-top: var(--purpur-spacing-1200);
  padding-bottom: var(--purpur-spacing-1200);

  @media (max-width: 37.5em) {
    // should really be purpurBreakpointRelativeMd
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

export const GapContainerCentered = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--purpur-spacing-400);

  max-width: 56rem;
  margin: auto;
  text-align: center;
`;

export const IconWrapper = styled.div`
  margin: auto;
  text-align: center;

  padding: var(--purpur-spacing-400);
  border-radius: 50%;
  background: var(--purpur-color-transparent-black-50);

  @media (min-width: 37.5em) {
    // should really be purpurBreakpointRelativeMd
    svg {
      width: var(--purpur-spacing-1600);
      height: var(--purpur-spacing-1600);
    }
  }
`;

const NotFound = () => {
  const titleText = useMessage("title");
  const infoText = useMessage("info-text");
  const buttonText = useMessage("button-text");

  const returnUrl = B2B_BASE_URL;

  return (
    <PageContainer id="notFoundPage" data-testid="b2b-not-found-page-root">
      <Grid>
        <Grid.Item>
          <GapContainerCentered>
            <IconWrapper>
              <PageNotFoundSvg data-testid="b2b-not-found-page-icon" />
            </IconWrapper>

            <Heading tag={"h1"} variant="title-400" data-testid="b2b-not-found-page-title">
              {titleText}
            </Heading>

            <Paragraph variant="preamble-100" data-testid="b2b-not-found-page-info-text">
              {infoText}
            </Paragraph>

            <CtaLink
              href={returnUrl}
              variant="primary"
              data-testid="b2b-not-found-page-go-back-button"
              fullWidth
            >
              {buttonText}
            </CtaLink>
          </GapContainerCentered>
        </Grid.Item>
      </Grid>
    </PageContainer>
  );
};

export default NotFound;
